<template>
    <div class="context">
        <div class="tool">
             <el-button type="primary" class="el-icon-plus marg" @click="AddClick"> 新增</el-button>
             <el-button type="primary" class="el-icon-download" @click="Download"> 下载</el-button>
             <el-input placeholder="请输入服务商名称" v-model="searchForm.searchInfo" style="width:200px;margin-left:10px">
                <el-button slot="append" icon="el-icon-search" @click='Search'></el-button>
            </el-input>
        </div>

        <el-table :data='DataList' ref="qrcodeTable" border stripe :highlight-current-row='true' max-height='calc(100vh - 180px)' height='calc(100vh - 180px)'>
             <el-table-column align="center" type="selection" width="50"></el-table-column>
            <el-table-column align="center" prop="qc_id" label="编号"></el-table-column>
            <el-table-column align="center" prop="qc_create_time" label="创建时间">
                <template slot-scope="slot">
                    {{FormateData(slot.row.qc_create_time,2)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="qc_enabel_time" label="启用时间">
                <template slot-scope="slot">
                    {{FormateData(slot.row.qc_enabel_time,2)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="sp_name" label="服务商"></el-table-column>
            <el-table-column align="center" prop="media_name" label="点位名称"></el-table-column>
            <el-table-column align="center" prop="qc_no" label="二维码" width="1">
                <template slot-scope="scope" >
                    <VueQr :text="url+ scope.row.qc_no" :size="300" :callback="qrcode_data" :logoSrc="logoPath" :logoScale="0.2"  :qid="scope.row.qc_id.toString()" v-show="false"></VueQr>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="fy" @current-change="current_change"  :page-sizes="[20, 50, 100, 150]" :total="searchForm.total" background :page-size="searchForm.pageSize"  layout="total, sizes, prev, pager, next, jumper" @size-change="size_change"></el-pagination>

         <el-dialog :title="title" :visible.sync="dialogVisible" width='40%' :append-to-body='true'>
            <el-form label-width="100px" size='medium'>
                <el-form-item label="生成数量：" >
                    <el-input-number v-model="num" controls-position="right" :min="1" style="width:100%"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'
import VueQr from 'vue-qr'
import JSZip from 'jszip'
import filesave from 'file-saver'
import attr from '../../../vue.config'

export default {
  data: function () {
    return {
      logoPath: require('../../assets/qrlogo.png'),
      dialogVisible: false,
      url: attr.devServer.proxy['/api'].target + '/devcode/register?code=',
      title: '新增',
      DataList: [],
      searchForm: {
        searchInfo: '',
        pageSize: 20,
        pageNo: 1,
        total: 0
      },
      num: 1
    }
  },
  components: {
    VueQr
  },
  computed: {
    FormateData () {
      return function (time, type) {
        if (time !== undefined && time !== null) { return util.FormateDate(time, type) } else { return '' }
      }
    }
  },
  created () {
    console.log(this.url)
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      util.Get('devcode/getdatalist', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          if (res.list.length > 0) {
            res.list.forEach(a => {
              a.imgUrl = ''
            })
          }
          this.DataList = res.list
          this.searchForm.total = res.total
        }
      })
    },
    Search(){
      this.pageNo=1
      this.GetDataList()
    },
    qrcode_data (dataUrl, id) {
      var arr = this.DataList.filter(a => a.qc_id === parseInt(id))
      if (arr.length > 0) {
        arr[0].imgUrl = dataUrl
      }
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    size_change(value){
      this.searchForm.pageSize=value
      this.GetDataList()
    },
    AddClick () {
      this.dialogVisible = true
    },
    submit () {
      util.Get('devcode/edit?num=' + this.num).then(res => {
        if (res.rpStatus === 10000) {
          this.GetDataList()
          this.dialogVisible = false
        }
      })
    },
    Download () {
      var rows = this.$refs.qrcodeTable.selection
      if (rows.length > 0) {
        var blogTitle = '二维码' // 图片压缩包名称
        var zip = new JSZip()
        var imgs = zip.folder(blogTitle)
        var baseList = [] // 需要压缩图片的路径
        var imgNameList = [] // 单张图片名称

        var arr = []

        for (var i = 0; i < rows.length; i++) {
          imgNameList.push(rows[i].qc_id.toString())
          const image = new Image()
          // 生成二维码
          arr.push(rows[i].imgUrl)

          image.src = rows[i].imgUrl

          var canvas = document.createElement('canvas') // 准备空画布
          canvas.width = 300
          canvas.height = 300
          var context = canvas.getContext('2d')
          image.onload = function () {
            context.drawImage(image, 0, 0, 300, 300)
            const url = canvas.toDataURL()
            canvas.toDataURL('image/png')
            baseList.push(url.substring(22)) // 去掉base64编码前的 data:image/png;base64,

            if (baseList.length === arr.length && baseList.length > 0) {
              for (let k = 0; k < baseList.length; k++) {
                imgs.file(imgNameList[k] + '.png', baseList[k], { // 设置单张图片名称
                  base64: true
                })
              }
              zip.generateAsync({
                type: 'blob'
              }).then(function (content) {
                // 调用 FileSaver.js
                filesave.saveAs(content, blogTitle + '.zip')
              })
            }
            image.src = arr[i]
          }
        }
      } else {
        this.$message.error('请选择要下载的二维码')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.context{
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
}
.tool{
display: flex;
    flex-direction: row;
    justify-content: start;
}
.el-table{
    margin-top: 10px;
}

::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
